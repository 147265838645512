<template>

    <div id="menu-left-cg" class="vs-sidebar vs-sidebar-primary">
        <div class="vs-sidebar--items">
            <div slot="header" class="header-sidebar flex items-end menulist">
                <router-link tag="div" class="vx-logo cursor-pointer flex items-center" to="/">
                    <img src="@/assets/images/ehpad/logo.png" alt=""/>
                </router-link>
            </div>
            <section class="ps-container scroll-area-v-nav-menu pt-2 ps ps--theme_default ps--active-y">
                <div class="menu">
                    <a href="#h001" class="selected">1 - Définitions</a><br />
                    <a href="#h002">2 - Objet</a><br />
                    <a href="#h003">3 - Informations relatives au vendeur</a><br />
                    <a href="#h004">4 - Application et acceptation des CGV</a><br />
                    <a href="#h005">5 - Modification des CGV</a><br />
                    <a href="#h006">6 - Produits disponibles à la vente</a><br />
                    <a href="#h007">7 - Caractéristiques des Produits</a><br />
                    <a href="#h008">8 - Commande sur la Plateforme Happydom</a><br />
                    <a href="#h009">9 - Prix des Produits</a><br />
                    <a href="#h010">10 - Paiement</a><br />
                    <a href="#h011">11 - Refus/rejet de paiement par carte bancaire ou prélèvement sur compte bancaire</a><br />
                    <a href="#h012">12 - Suspension ou annulation de la Commande par Happytal</a><br />
                    <a href="#h013">13 - Modification ou annulation de la Commande par le Client</a><br />
                    <a href="#h014">14 - Conditions d’exercice du droit de rétractation</a><br />
                    <a href="#h015">15 - Dérogations au droit de rétractation</a><br />
                    <a href="#h016">16 - Indisponibilité</a><br />
                    <a href="#h017">17 - Livraisons de Produits</a><br />
                    <a href="#h018">18 - Retard de livraison de Produits</a><br />
                    <a href="#h019">19 - Réserve de propriété des Produits</a><br />
                    <a href="#h020">20 - Réclamations</a><br />
                    <a href="#h021">21 - Conformité - Garantie – responsabilité</a><br />
                    <a href="#h022">22 - Garantie légale de conformité</a><br />
                    <a href="#h023">23 - Garantie des vices cachés</a><br />
                    <a href="#h024">24 - Limitation de responsabilité</a><br />
                    <a href="#h025">25 - Continuité et transfert des droits et obligations du contrat</a><br />
                    <a href="#h026">26 - Nullité d’une clause</a><br />
                    <a href="#h027">27 - Non-renonciation</a><br />
                    <a href="#h028">28 - Litiges - médiateur de la consommation</a><br />
                    <a href="#h029">29 - Juridiction compétente</a>
                    <a href="#h030">30 - Loi applicable</a>
                </div>
            </section>

        </div>
    </div>
</template>


<script>
    export default {
        name: 'v-nav-menu-cg',
        components: {},
        props: {},
        data: () => ({}),
        computed: {},
        watch: {},
        methods: {},
        mounted() {
        }
    }

</script>


<style lang="scss">
    @import "@/assets/scss/vuexy/components/verticalNavMenu.scss";
#menu-left-cg {
    .vx-logo {
        padding: 0 30px 0 0;
    }

    .item-mini-menu {
        font-size: 0.8em !important;
        margin-left: 3px;
        margin-top: 1px;
    }

    .menu a {
        margin-top: 4px;
        display: inline-block;
        color: #2C2C2C;
        font-size: 11px;
        text-transform: uppercase;
        &.selected {
            color:rgba(var(--vs-primary), 1);
        }
    }

    .menulist {
        flex-direction: column;
    }
    .ps-container {
        width: 80%;
        margin: 10px auto 0 auto;
    }
}
</style>
