<template>
    <div id="cgv">
        <v-nav-menu-cgv/>
        <div class="content-area-reduced" id="content-area">
            <the-navbar-vertical-public :theTitle="title"/>
            <div class="vx-col w-4/5 sm:tw-w-4/5 md:tw-w-3/5 lg:tw-w-3/4 xl:tw-w-4/5 mx-auto globalelement">
                <div class="vx-col tw-w-full mt-12 md:tw-mt-0 divscroll">
                    <h2 id="h001">1 - Définitions</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Chaque terme défini ci-dessous a le sens suivant :<br/><br/>
                        <ul>
                            <li>
                                • « <b>Happytal</b> » : la société HDC Santé, société par action simplifiée (SAS) au capital social de 13.198 euros, dont le siège social est situé au 59-61 bis rue Pernety, 75014 Paris, inscrite au registre du Commerce et des Sociétés de Nanterre sous le numéro 790 294 615. HDC Santé est titulaire de la marque happytal, nom commercial sous lequel elle opère ses services.
                            </li>
                            <li>
                                • « <b>Etablissement</b> » : établissement d'accueil pour personnes âgées, privé ou
                                public, ayant conclu un contrat avec Happytal afin de mettre à disposition de ses
                                Résidents, de leurs proches et/ou de leurs tuteurs la Plateforme Happydom.
                            </li>
                            <li>
                                • « <b>CGV</b> » : les présentes conditions générales de vente qui régissent
                                les conditions commerciales applicables entre Happytal et le Client souhaitant
                                commander un ou plusieurs Produit(s) via la Plateforme Happydom.
                            </li>
                            <li>
                                • « <b>Client</b> » : personne physique (proche-aidant ou tuteur d’un Résident)
                                titulaire d’un Compte lui donnant accès à la Plateforme Happydom, souhaitant
                                passer une Commande via la Plateforme Happydom de Produit(s) destiné(s) à
                                être fourni(s) directement à un Résident dans son Établissement d’accueil.
                            </li>
                            <li>
                                • « <b>Commande</b> » : la commande réalisée via la Plateforme Happydom par
                                un Client. Il peut s’agir d’une Commande ponctuelle ou de Commandes
                                récurrentes. Outre les Produits proposés à la vente par Happytal, la
                                Commande peut contenir des produits et/ou services de vendeurs ou
                                prestataires de services indépendants et référencés sur la Plateforme Happydom.
                            </li>
                            <li>
                                • « <b>Compte</b> » : le compte du Client enregistré au sein de la Plateforme
                                Happydom, permettant à ce dernier de procéder à une Commande ponctuelle et/ou
                                de modifier le Plan de Confort Personnalisé du Résident générant des Commandes
                                récurrentes, et de suivre l’ensemble de ses Commandes.
                            </li>
                            <li>
                                • « <b>Date de Commande</b> » : date à laquelle une Commande ponctuelle est
                                validée par le Client ou date à laquelle une Commande récurrente est générée
                                automatiquement à partir du Plan de Confort  Personnalisé du Résident défini
                                par le Client.
                            </li>
                            <li>
                                • « <b>Date de Livraison</b> » : la date à laquelle le Produit est remis à
                                l’Établissement et réceptionné par un membre du personnel de l'Établissement.
                            </li>
                            <li>
                                • « <b>Plan de Confort Personnalisé</b> » : le(s) produit(s) et/ou
                                service(s) sélectionné(s) par le Client via la Plateforme Happydom,
                                en ce compris le(s) Produit(s), destiné(s) à faire l’objet de
                                Commandes récurrentes en vue de livraisons au Résident selon
                                un cadencement défini par le Client.
                            </li>
                            <li>
                                • « <b>Partie</b> » : au singulier, indifféremment le Client ou
                                Happytal et, au pluriel, ensemble le Client et Happytal.
                            </li>
                            <li>
                                • « <b>Plateforme Happydom</b> » : la solution en ligne opérée par la société Happytal
                                afin de mettre en relation le Client avec des vendeurs et/ou prestataires de services,
                                permettant au Client d’acheter des produits ou services destinés à être fournis à un
                                Résident dans son Etablissement de résidence.
                            </li>
                            <li>
                                • « <b>Produit(s)</b> » : les biens proposés à la vente par Happytal, en tant
                                qu’acheteur-revendeur, via la Plateforme Happydom et susceptibles d’être
                                livrés au Résident dans son Etablissement.
                            </li>
                            <li>
                                • « <b>Résident</b> » : la personne physique résidant au sein de
                                l'Etablissement et ayant vocation à recevoir un ou plusieurs
                                Produit(s) commandé(s) par le Client.
                            </li>
                        </ul>
                        <br/>

                        Sauf précision particulière, ces termes ont le même sens ainsi défini qu’ils soient utilisés au
                        singulier au pluriel.
                    </div>
                    <h2 id="h002">2 - Objet</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Les présentes CGV ont pour objet de définir les droits et
                        obligations des Parties dans le cadre des ventes de
                        Produit(s) réalisées par Happytal aux Clients via la
                        Plateforme Happydom.
                    </div>
                    <h2 id="h003">3 - Informations relatives au vendeur Happytal</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        <ul>
                            <li>
                                • La société HDC Santé, société par action simplifiée
                                (SAS) au capital social de 13.198 euros,
                            </li>
                            <li>
                                • Siège social : 59-61 bis rue Pernety, 75014 Paris,
                            </li>
                            <li>
                                • Inscription au registre du Commerce et des Sociétés
                                de Nanterre sous le numéro 790 294 615,
                            </li>
                            <li>
                                • Numéro de TVA intracommunautaire : FR 60 790294615,
                            </li>
                            <li>
                                • Courriel : contact@happytal.com
                            </li>
                            <li>
                                • Téléphone : +33 1 86 76 05 98 (n° non surtaxé)
                            </li>
                        </ul>
                    </div>
                    <h2 id="h004">4 - Application et acceptation des CGV</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Les présentes CGV s’appliquent, sans restriction ni réserve à toute Commande réalisée
                        auprès de Happytal via la Plateforme Happydom.<br/><br/>

                        Les présentes CGV n’ont pas vocation à régir les relations commerciales
                        entre les Clients et des vendeurs de produits ou prestataires de
                        services indépendants de Happytal, référencés par Happytal sur
                        la Plateforme Happydom et vendant leurs produits ou services
                        en leur nom et pour leur propre compte.<br/><br/>

                        Les CGV sont consultables à tout moment, dans la rubrique
                        « <a href="https://www.happydom.fr/legal/cgv" target="_blank">https://www.happydom.fr/legal/cgv</a> »
                        de la Plateforme Happydom, uniquement en langue française.<br/><br/>

                        La validation de la Commande de Produit(s) par le Client est
                        subordonnée à l’acceptation des CGV. En cliquant sur le
                        bouton « lu et accepté » lors de la validation de la Commande
                        (s’agissant d’une Commande ponctuelle) ou lors de la saisie
                        ou de la modification du Plan de Confort Personnalisé
                        (s’agissant de Commandes récurrentes), le Client déclare
                        et reconnait avoir pris connaissance des présentes CGV
                        préalablement à toute Commande, les avoir comprises et
                        les accepter sans réserves. Il peut les sauvegarder ou imprimer.<br/><br/>

                        Pour passer une Commande, le Client doit être une personne physique ayant la qualité
                        de consommateur au sens du droit français, âgée de plus de 18 ans et ayant la
                        pleine capacité juridique de contracter.
                    </div>
                    <h2 id="h005">5 - Modification des CGV</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Happytal se réserve la possibilité de modifier et de mettre à jour
                        les CGV sans préavis. Pour être informé de ces éventuelles modifications,
                        Happytal invite le Client à relire régulièrement les CGV accessibles sur
                        la Plateforme Happydom. Sans préjudice de ce qui précède, la Commande
                        sera soumise à la version des CGV en vigueur et portée à la connaissance
                        du Client au moment de la Date de Commande.
                    </div>
                    <h2 id="h006">6 - Produits disponibles à la vente</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        La liste des Produits disponibles à la vente en vue de leur livraison
                        dans un Etablissement donné est susceptible de varier en fonction
                        de l'Etablissement concerné et peut faire l’objet de modifications
                        à tout moment, sans préjudice des Commandes en cours. La liste des
                        Produits disponibles à la vente est précisée sur la Plateforme Happydom.
                    </div>
                    <h2 id="h007">7 - Caractéristiques des Produits</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Le Client peut, en cliquant sur chaque Produit, en connaître
                        les caractéristiques essentielles.
                    </div>
                    <h2 id="h008">8 - Commande sur la Plateforme Happydom</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        La réalisation d'une Commande à partir de la Plateforme Happydom n’est
                        possible que via le Compte du Client.<br/><br/>

                        Le Client peut choisir de procéder à une Commande ponctuelle
                        et/ou des Commandes récurrentes :<br/><br/>

                        <ul>
                            <li>
                                • Commande ponctuelle : la Commande ne donnera lieu qu'à une seule livraison ; ou
                            </li>
                            <li>
                                • Commandes récurrentes : le Plan de Confort Personnalisé donne lieu à
                                la génération automatique d’une Commande récurrente globale le 1er de
                                chaque mois incluant le(s) produit(s) et/ou service(s), en ce compris
                                les Produit(s) y figurant à cette date ainsi que les éventuels produit(s)
                                et/ou service(s) fourni(s) par des tiers via la Plateforme Happydom, et
                                destiné(s) à être livré(s) au cours du mois calendaire suivant. Pour
                                chaque Produit ajouté au Plan Personnalisé, le Client définit le
                                cadencement des livraisons. Le Client reçoit un rappel par email cinq (5)
                                jours calendaires avant la Date de Commande. Il lui appartient de
                                vérifier le contenu du Plan de Confort Personnalisé
                                (notamment la liste du/des Produits figurant au Plan Personnalisé
                                ou des conditions commerciales applicables auxdits Produits).
                                Le Client peut modifier le Plan de Confort Personnalisé à tout
                                moment, la modification étant prise en compte dès la Commande
                                récurrente suivante.
                            </li>
                        </ul>
                        <br/>

                        Le Client reçoit dans un délai de cinq (5) jours calendaires maximum
                        suivant la Date de Commande, à l’adresse renseignée dans son Compte,
                        un email de confirmation (avec un récapitulatif de la Commande) ou
                        de refus de tout ou partie de la Commande.<br/><br/>

                        En cas d'émission mais de non réception de l'email de confirmation de la
                        Commande, la responsabilité de Happytal ne saurait être engagée. Dans ce
                        cas, la vente sera considérée comme définitive, hors les cas de modification
                        ou d’annulation de la Commande. Le Client pourra en tout état de cause exercer
                        son droit de rétractation dans les conditions prévues aux présentes CGV.
                    </div>
                    <h2 id="h009">9 - Prix des Produits</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Les prix de vente des Produits sont indiqués en euros toutes taxes comprises.<br/>
                        Les prix de vente des Produits peuvent être modifiés par Happytal à tout moment.<br/>
                        <ul>
                            <li>
                                • Dans le cas d’une Commande ponctuelle, le prix en vigueur
                                est indiqué au Client avant toute validation de Commande et
                                les Produits commandés sont facturés aux prix en vigueur
                                au moment de la validation de la Commande par le Client.
                            </li>
                            <li>
                                Dans le cas d’une Commande récurrente, les Produits sont
                                facturés aux prix en vigueur à la Date de Commande. Les prix
                                sont indiqués au Client lors de la saisie et/ou de la modification
                                du Plan de Confort Personnalisé, et consultables à tout moment
                                par le Client via son Compte. Dans l’hypothèse d’une augmentation
                                du prix d’un Produit (entre l’ajout dudit Produit au Plan de
                                Confort Personnalisé par le Client et la Date de Commande),
                                l’envoi de la Commande est suspendu tant que le Client n’a
                                pas validé celle-ci. Un email est adressé au Client à cet
                                effet. A défaut de validation de la Commande par le Client
                                dans un délai de quarante-huit (48) heures suivant la
                                réception de la notification de la suspension de sa Commande,
                                la totalité de ladite Commande récurrente est annulée pour
                                la seule échéance concernée.
                            </li>
                        </ul>
                        Nonobstant ce qui précède, le Client peut, dans le cadre du
                        paramétrage de son Compte, consentir par avance à une éventuelle
                        augmentation du montant global cumulé de la Commande dans la
                        limite d’un pourcentage prédéfini de son choix. A défaut de
                        modification expresse par le client, le pourcentage d’augmentation
                        autorisée paramétré dans le Compte est égal à zéro (0). Si
                        le Client a paramétré un pourcentage d’augmentation autorisé,
                        l’éventuelle augmentation du prix d’un Produit ne donnera pas
                        lieu à la suspension de l’envoi de la Commande, qui sera
                        réputée validée, dès lors que l’éventuelle augmentation du
                        montant global cumulé de la Commande n’excède pas le
                        pourcentage d’augmentation autorisé par le Client.<br/>
                        Les prix de vente des Produits ne comprennent pas les
                        éventuels frais de livraison applicables, frais qui sont
                        identifiés séparément et indiqués au Client avant la
                        validation de la Commande (s’agissant d’une Commande
                        ponctuelle) ou lors de la saisie et/ou de la modification
                        du Plan de Confort Personnalisé, et consultables à tout
                        moment par le Client via son Compte (s’agissant d’une
                        Commande récurrente).
                    </div>
                    <h2 id="h010">10 - Paiement</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Toutes les Commandes sont payables en euros. Le paiement de la Commande
                        s’effectue au moyen d’une carte bancaire ou par prélèvement automatique sur
                        compte bancaire renseigné par le Client dans son Compte.<br/><br/>

                        Le Client garantit à Happytal qu’il est pleinement autorisé à utiliser la carte
                        bancaire et/ou le compte bancaire utilisés pour le paiement de sa Commande et
                        que ces moyens de paiement donnent légalement accès à des fonds suffisants pour
                        couvrir tous les coûts résultant de sa Commande sur la Plateforme Happydom.
                        Happytal ne pourra être tenue pour responsable de tout usage frauduleux du moyen
                        de paiement utilisé.<br/><br/>

                        S’agissant d’une Commande ponctuelle, le montant de la Commande sera débité
                        dans les cinq (5) jours calendaires suivant la validation de la Commande
                        par le Client.<br/><br/>

                        S’agissant d’une Commande récurrente, le montant de la Commande sera débité
                        lorsque l’ensemble des produits et services figurant sur la Commande
                        (les Produits vendus par Happytal mais également les éventuels produit(s)
                        et/ou service(s) fourni(s) par des tiers figurant sur la même Commande)
                        sont réputés livrés/exécutés :<br/>
                        - Un produit est réputé livré en l’absence de contestation de la part du
                        Client dans le délai de 21 jours calendaires après sa date d’envoi par le vendeur ; et<br/>
                        - Un service est réputé exécuté au jour où le prestataire indique via son compte sur
                        la Plateforme Happydom qu’il a été fourni.<br/><br/>

                        En ce sens, le dernier produit et/ou service réputé livré et/ou
                        exécuté déclenche le paiement de la Commande récurrente.<br/><br/>

                        Afin qu’un éventuel défaut ou retard de livraison ou d’exécution
                        produit(s) et/ou service(s) ne bloque pas le paiement du/des
                        produit(s) et/ou service(s) livrés/exécutés, une Commande
                        récurrente peut être partiellement annulée par l’opérateur
                        de la Plateforme Happydom dans un délai maximal de trente
                        (30) jours calendaires à compter de la Date de Commande.
                        En conséquence, les montants correspondant aux ventes de
                        Produits livrés par Happytal seront débités dans un délai
                        maximal de trente (30) jours calendaires à compter de
                        la Date de Commande.<br/><br/>

                        Il appartient au Client d’enregistrer et d’imprimer son certificat de paiement
                        s’il souhaite conserver les détails bancaires relatifs à sa transaction.
                    </div>
                    <h2 id="h011">11 - Refus/rejet de paiement par carte bancaire ou prélèvement sur compte
                        bancaire</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Le Client sera informé dans les meilleurs délais de tout refus/rejet de
                        paiement par carte bancaire ou du prélèvement sur le compte bancaire
                        renseigné par le Client. Le Client reconnaît que tout refus/rejet de
                        prélèvement par carte bancaire ou sur compte bancaire entraîne des
                        frais à la charge de Happytal. Dans l'hypothèse d'un tel refus/rejet
                        de paiement par carte bancaire ou du prélèvement sur le compte bancaire,
                        Happytal facturera le Client d'une pénalité forfaitaire d'un montant de 7€.
                    </div>
                    <h2 id="h012">12 - Suspension ou annulation de la Commande par Happytal</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Happytal se réserve le droit de suspendre ou d’annuler toute exécution d’une
                        Commande, quels que soient sa nature et son niveau d’exécution, en cas :

                        <ul>
                            <li>
                                • de défaut de paiement, de paiement partiel ou d’incident de paiement de toute somme
                                due par le Client, y compris au titre d’une Commande distincte ;
                            </li>
                            <li>
                                • de fraude ou tentative de fraude relative à l’utilisation de la Plateforme Happydom,
                                par le Client ou via son Compte, y compris à l’occasion de Commandes antérieures ;
                            </li>
                            <li>
                                • de notification reçue par Happytal du décès du Résident auquel le(s) Produit(s)
                                sont destinés, de son départ ou de son absence prolongée de l’Etablissement.
                            </li>
                        </ul>
                        <br/>

                        La suspension ou l’annulation de la Commande ainsi que son motif est portée à
                        la connaissance du Client par email.
                    </div>
                    <h2 id="h013">13 - Modification ou annulation de la Commande par le Client</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Le Client peut modifier ou annuler toute Commande (ponctuelle ou récurrente)
                        de Produit(s) tant que celle-ci n’a pas fait l’objet d’une confirmation par
                        Happytal, en contactant Happytal par téléphone via les coordonnées figurant
                        aux présentes CGV.  Une fois la Commande confirmée par Happytal, la vente
                        sera considérée comme définitive et la Commande ne pourra plus être annulée
                        ou modifiée unilatéralement par le Client.<br/><br/>

                        Ceci est sans préjudice de la possibilité pour le Client d’exercer son droit
                        de rétractation dans les conditions prévues aux présentes CGV et/ou de
                        contacter Happytal afin de parvenir à un accord entre les Parties sur
                        ladite modification ou annulation de Commande.<br/><br/>

                        Le Client reçoit dans un délai de deux (2) jours calendaires
                        suivant sa demande de modification ou d’annulation, à l’adresse
                        renseignée dans son Compte, un email de confirmation de la
                        modification (avec un récapitulatif de la nouvelle Commande)
                        ou d’annulation.<br/><br/>

                        Le client reconnait et accepte que la suppression du Compte entraîne l’annulation
                        automatique des Commandes en cours mais non exécutées (ou en cours d’exécution).<br/><br/>

                        De même, dans le cas de la suppression par le Client via son Compte d'un
                        sous-compte (dédié à un Résident sur la Plateforme Happydom), les
                        Commandes en cours mais non exécutées (ou en cours d’exécution)
                        destinées audit Résident seront automatiquement annulées.<br/><br/>

                        En toute hypothèse, le paiement des Commandes exécutées devra être effectué par
                        le Client.
                    </div>
                    <h2 id="h014">14 - Conditions d’exercice du droit de rétractation</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Conformément à l’article L.221-18 du code de la consommation,
                        le Client dispose d’un délai de rétractation de quatorze (14) jours
                        calendaires pour chaque Produit, à compter de la Date de Livraison
                        de celui-ci. Dans le cas où le délai de rétractation expirerait un
                        samedi, un dimanche ou un jour férié ou chômé, le délai est
                        prolongé jusqu’au premier jour ouvrable suivant.<br/><br/>

                        Pour exercer ce droit de rétractation, le Client doit adresser
                        sa demande au moyen d'un formulaire joint en annexe et adressé
                        par courriel à l’adresse <a href="mailto:contact@happytal.com" target="_blank">contact@happytal.com</a>.<br/><br/>

                        Le montant correspondant à l’achat du/des Produit(s) ayant
                        fait l’objet d’une rétractation par le Client est remboursé,
                        par un crédit sur le compte bancaire utilisé pour le paiement
                        dans un délai maximum de quatorze (14) jours calendaires :
                        <ul>
                            <li>
                                • à compter de la réception par Happytal des  Produit(s) retourné(s), ou
                            </li>
                            <li>
                                • à compter de la réception par Happytal du formulaire de rétraction
                                s’agissant de Produit(s) non-encore expédiés.
                            </li>
                        </ul>
                    </div>
                    <h2 id="h015">15 - Dérogations au droit de rétractation</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        L’existence d’un droit de rétractation dépend de la nature du Produit
                        commandé par le Client.<br/><br/>

                        Les dispositions de l’article L.221-18 du code de la consommation
                        ne sont pas applicables aux Produits relevant des catégories
                        de biens énoncés à l’article L.221-28 du code de la consommation,
                        notamment :<br/><br/>

                        <ul>
                            <li>
                                • les Services pleinement exécutés avant la fin du délai de rétractation et
                                dont l'exécution a commencé après accord préalable exprès du Client et
                                renoncement exprès à son droit de rétractation ;
                            </li>
                            <li>
                                • les Produits susceptibles de se détériorer ou de se périmer rapidement ;
                            </li>
                            <li>
                                • les Produits descellés par le consommateur après la livraison et qui
                                ne peuvent être renvoyés pour des raisons d’hygiène ou de protection de la santé ;
                            </li>
                            <li>
                                • les Produits confectionnés selon les spécifications du Client ou nettement
                                personnalisés ;
                            </li>
                            <li>
                                • les journaux, périodiques ou magazines à l’exception des contrats
                                d’abonnement à ces publications.
                            </li>
                        </ul>
                        <br/>

                        Les Produits concernés sont désignés comme tels sur la Plateforme Happydom.
                    </div>
                    <h2 id="h016">16 - Indisponibilité</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Les offres présentées par Happytal sont valables tant qu’elles sont publiées
                        sur la Plateforme Happydom, dans la limite des stocks de Produits disponibles
                        de Happytal ou de ses propres fournisseurs. En cas d’indisponibilité d’un
                        Produit à la Date de Commande, la Commande de celui-ci sera refusée.<br/><br/>

                        En cas de retrait définitif d’un Produit du catalogue proposé par
                        Happytal, figurant dans un Plan de Confort Personnalisé défini par
                        le Client, ce dernier en sera informé par email et le Produit
                        concerné sera automatiquement supprimé du Plan de Confort Personnalisé.<br/><br/>

                        En cas d’indisponibilité d’un Produit après confirmation de la
                        Commande par Happytal, le Client sera averti par email que la
                        partie de la Commande correspondant à ce(s) Produit(s) est annulée.
                        Le montant de sa Commande sera recalculé sur la base de la Commande
                        initiale déduction faite du prix du/des Produit(s) manquant(s).<br/><br/>

                        En cas de Produit(s) temporairement indisponible(s) figurant dans
                        une Commande récurrente, la partie de la Commande correspondant à
                        ce(s) Produit(s) est annulée pour la période d'indisponibilité.
                        Le montant de la Commande concernée sera recalculé sur la base du
                        montant initialement prévu déduction faite du prix du/des
                        Produit(s) manquant(s).<br/><br/>

                        Le Client sera :<br/>

                        <ul>
                            <li>
                                • Soit débité du nouveau montant si le débit initialement fixé n’a pas encore été
                                exécuté,
                            </li>
                            <li>
                                • Soit remboursé de la différence en cas de débit du montant initialement fixé.
                            </li>
                        </ul>
                        <br/>

                        Si la Commande est entièrement indisponible le Client sera averti par email
                        de l’annulation de celle-ci (pour la période d’indisponibilité s’agissant
                        d’une Commande  récurrente) et ne sera pas débité ou sera remboursé de
                        la totalité du montant payé.<br/><br/>

                        En tout état de cause, Happytal ne pourra être tenue responsable
                        d’aucun préjudice subi par le Client en conséquence de l’indisponibilité
                        totale ou partielle des Produits, ni tenue à verser de dommages-intérêts
                        au Client.
                    </div>
                    <h2 id="h017">17 - Livraisons de Produits</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Les Produits commandés seront livrés à l’adresse de l'Etablissement d'accueil du
                        Résident auquel les Produits sont destinés.<br/><br/>

                        Happytal fera ses meilleurs efforts pour que les Produits soient livrés aux
                        périodes de livraison indiquées au Client lors de sa Commande et dans l’email
                        de confirmation de la Commande.<br/><br/>

                        A la Date de Livraison, le membre du personnel de l’Etablissement qui réceptionne
                        la Commande de Produit(s) signe le bon de livraison au nom et pour le compte du
                        Client, ce que le Client accepte expressément. Le Client sera informé de la
                        livraison par email et directement via son Compte sur la Plateforme Happydom.<br/><br/>

                        Le Client reconnait et accepte que les risques tenant au(x) Produit(s) lui sont
                        transférés à la Date de Livraison.
                    </div>
                    <h2 id="h018">18 - Retard de livraison de Produits</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        En cas de retard d’exécution de la livraison par rapport à la période indiquée
                        dans la confirmation de la Commande, Happytal informe le Client du retard, par
                        email et directement via son Compte, et lui indique la nouvelle date de livraison
                        prévue.<br/><br/>

                        Si la nouvelle date de livraison prévue représente un retard de plus de cinq
                        (5) jours calendaires pour un Produit à livraison récurrente hebdomadaire
                        ou bimensuelle, ou plus de dix (10) jours calendaires pour un Produit
                        objet d’une Commande ponctuelle ou à livraison récurrente mensuelle
                        ou pluri-mensuelle, le Client pourra décider d’annuler la Commande
                        du/des Produit(s) concerné(s), en contactant Happytal via les
                        coordonnées téléphoniques indiquées dans les présentes CGV au
                        plus tard deux (2) jours ouvrés après avoir reçu la notification du retard.<br/><br/>

                        En cas d’annulation par le Client respectant les conditions
                        susvisées, celui-ci est remboursé des sommes éventuellement
                        débitées dans un délai de quatorze (14) jours calendaires
                        suivant la demande d’annulation.<br/><br/>

                        En dehors des cas d’annulation susvisés, aucune annulation
                        due à un retard de livraison ne sera acceptée sauf exercice
                        par le Client de son droit de rétractation dans les conditions
                        prévues à cet effet et annulation ou modification de la
                        Commande dans les conditions prévues par les présentes CGV.<br/><br/>

                        Aucune indemnisation ne pourra être réclamée à la société Happytal au titre
                        d’un retard de livraison, qu’il ait ou non donné lieu à une annulation de
                        la Commande concernée.
                    </div>
                    <h2 id="h019">19 - Réserve de propriété des Produits</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Happytal conserve la propriété pleine et entière des Produits vendus jusqu’au
                        parfait encaissement du prix intégral, en principal, frais, taxes et
                        contributions obligatoires compris.
                    </div>
                    <h2 id="h020">20 - Réclamations</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Toute réclamation du Client s'agissant de Produit(s) peut
                        être communiquée via l'adresse email : <a href="contact@happytal.com" target="_blank">contact@happytal.com</a>.<br/><br/>

                        Le Client  reconnait que l’Etablissement procède à la vérification
                        du/des Produit(s) lors de leur réception et à la notification à
                        Happytal de toute réserve sur le/les Produit(s) livré(s) (par
                        exemple : colis endommagé, déjà ouvert, pièces manquantes,
                        Produit ne correspondant pas à la Commande, etc.) dans les
                        deux (2) jours ouvrés suivants la réception du/des Produit(s).<br/><br/>

                        Happytal informera le Client par email et directement via son Compte
                        dans l’hypothèse où des réserves lui sont signalées. Happytal
                        procèdera à une vérification auprès de l’Etablissement de la
                        réalité des non-conformités ou défauts apparents signalés.<br/><br/>

                        Dans le cas où la défectuosité apparente ou la non-conformité
                        du/des Produit(s) serait avérée, Happytal procèdera au remplacement
                        du/des Produit(s) à ses frais dans la limite des stocks disponibles ;
                        en cas de rupture de stock, et si le paiement de la Commande est
                        intervenu, le Client sera remboursé du montant correspondant
                        au(x) Produit(s) concerné(s), par un crédit du montant à rembourser
                        sur le compte bancaire utilisé pour le paiement de sa Commande.<br/><br/>

                        La faculté de réclamation est applicable sans préjudice d’un
                        éventuel droit de rétractation du Client dans les conditions
                        des présentes CGV, de l’existence de la garantie légale de
                        conformité ainsi que de la garantie des vices cachés.
                    </div>
                    <h2 id="h021">21 - Conformité - Garantie – responsabilité</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Happytal déclare que les Produits sont conformes à la législation
                        française en vigueur et que les Produits sont exempts de défauts.<br/><br/>

                        Happytal est soumise aux conditions de garanties légales dans les conditions
                        des articles L.217-4 et suivants du code de la consommation et de la garantie
                        des vices cachés dans les conditions prévues aux articles 1641 et suivants du
                        code civil.
                    </div>
                    <h2 id="h022">22 - Garantie légale de conformité</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Conformément aux articles L.217-4 et suivants du code de la consommation :

                        <ul>
                            <li>
                                • Le Client bénéficie d’un délai de deux (2) ans à compter de la Date de Livraison d'un
                                Produit pour agir (article L.217-12 du code de la consommation).
                            </li>
                            <li>
                                • Le Client est en droit d'exiger la conformité du Produit à la Commande.
                                Il ne peut cependant contester la conformité en invoquant un défaut qu'il
                                connaissait ou ne pouvait ignorer lorsqu'il a contracté
                                (article L.217-8 du code de la consommation).
                            </li>
                            <li>
                                • Le Client peut choisir entre la réparation ou le remplacement du Produit. Toutefois,
                                Happytal peut ne pas procéder selon le choix du Client si ce choix entraîne un coût
                                manifestement disproportionné au regard de l'autre modalité, compte tenu de la valeur du
                                Produit ou de l'importance du défaut. Happytal sera alors tenue de procéder, sauf
                                impossibilité, selon la modalité non choisie par le Client (article L.217-9 du code de
                                la consommation).
                            </li>
                            <li>
                                • Si la réparation et/ou le remplacement du Produit sont impossibles, le Client peut
                                rendre le Produit et se faire restituer le prix ou garder le Produit et se faire rendre
                                une partie du prix (article L.217-10 alinéa 1 du code de la consommation).
                            </li>
                        </ul>
                    </div>
                    <h2 id="h023">23 - Garantie des vices cachés</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Conformément aux articles 1641 et suivants du code civil:

                        <ul>
                            <li>
                                • Happytal est tenue de la garantie à raison des défauts cachés du/des Produit(s) qui
                                le/les rendent impropre(s) à l’usage auquel on le/les destine, ou qui diminuent
                                tellement cet usage que le Client ne l’aurait pas acquis, ou n’en aurait donné qu’un
                                moindre prix, s’il les avait connus (article 1641 du code civil).
                            </li>
                            <li>
                                • Le Client a le choix de rendre le Produit et de se faire restituer le prix, ou de
                                garder le Produit et de se faire rendre une partie du prix (article 1644 du code civil).
                            </li>
                            <li>
                                • L'action résultant des vices rédhibitoires doit être intentée par la Client dans un
                                délai de deux (2) ans à compter de la découverte du vice (article 1648 du code civil).
                            </li>
                        </ul>
                    </div>
                    <h2 id="h024">24 - Limitation de responsabilité</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Le Client assume l’entière responsabilité de la consommation ou de
                        l'utilisation de Produit(s) par le Résident, notamment en cas de
                        non-respect du régime alimentaire ou de non-respect de toutes
                        contre-indications médicales avant, pendant ou après son séjour
                        dans l’Etablissement.<br/><br/>

                        Le Client comprend qu'en aucun cas un Résident ne sera contraint
                        d’utiliser les Produits commandés et que le fait qu’un Résident
                        ne souhaite pas utiliser un Produit commandé ne donnera droit
                        à aucun remboursement ni indemnité de la part de Happytal.<br/><br/>

                        La responsabilité d’Happytal au titre de ses obligations ne saurait être engagée
                        dans le cas où l’inexécution de ses obligations serait imputable à la faute du
                        Client, ou à la survenance d’un événement de force majeure tel que défini par
                        l’article 1218 du code civil.<br/><br/>

                        La responsabilité d’Happytal ne pourra pas être retenue en cas de dommage causé
                        par l’utilisation non-conforme à la notice d’utilisation des Produits commandés.
                    </div>
                    <h2 id="h025">25 - Continuité et transfert des droits et obligations du contrat</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Le contrat ainsi que les droits et obligations entre Happytal et un Client ne
                        peuvent être cédés ou transférés par le Client à un tiers sans le consentement
                        écrit préalable de Happytal.<br/><br/>

                        Le contrat ainsi que les droits et obligations entre Happytal et un Client
                        peuvent être cédés ou transférés à un tiers sans consentement préalable du
                        Client uniquement si cette cession n'est pas susceptible d’engendrer une
                        diminution quelconque des droits du Client.
                    </div>
                    <h2 id="h026">26 - Nullité d’une clause</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Si une ou plusieurs stipulations des présentes CGV devaient pour une raison
                        quelconque être considérées comme nulles ou inopposables, les autres
                        stipulations des présentes CGV conserveront toute leur force et leur portée.
                    </div>
                    <h2 id="h027">27 - Non-renonciation</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Le fait pour l’une des Parties de ne pas se prévaloir auprès de l’autre Partie
                        d’un manquement à l’une quelconque des obligations visées au sein des présentes
                        CGV ne saurait être interprété pour l’avenir comme une renonciation à
                        l’obligation en cause.
                    </div>
                    <h2 id="h028">28 - Litiges - médiateur de la consommation</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Tout litige non résolu amiablement dans le cadre d’une réclamation peut faire
                        l’objet d’une saisine du médiateur désigné par Happytal. : Happytal adhère au
                        système de médiation FEVAD dont les coordonnées sont les suivantes :<br/><br/>

                        <ul>
                            <li>
                                • Courriel : <a href="contact@fevad.com" target="_blank">contact@fevad.com</a>
                            </li>
                            <li>
                                • Téléphone : <a href="tel:+33142563886" target="_blank">+33 1 42 56 38 86</a>.
                            </li>
                        </ul>
                        <br/>

                        Le Client n’encourt aucun frais lié à l’éventuelle saisine d’un médiateur.<br/><br/>

                        Le médiateur peut être saisi de tout litige lié à la vente d’un
                        Produit, sous réserve du respect des conditions de recevabilité du
                        dossier du Client.<br/><br/>

                        La Commission européenne a mis en place une plateforme de résolution des litiges
                        destinée à recueillir les éventuelles réclamations de consommateurs
                        faisant suite à un achat en ligne afin de les transmettre ensuite
                        aux médiateurs nationaux compétents. Cette plateforme est
                        accessible sous le lien suivant : <a href="http://ec.europa.eu/consumers/odr" target="_blank">http://ec.europa.eu/consumers/odr</a><br/><br/>

                        En cas d'échec d'une éventuelle médiation, l'une ou l'autre Partie pourra
                        saisir la juridiction compétente indiquée ci-dessous.
                    </div>
                    <h2 id="h029">29 - Juridiction compétente</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Tout litige relatif à l’exécution ou l’interprétation des présentes CGV sera
                        soumis aux tribunaux matériellement et territorialement compétents conformément
                        au droit commun. Conformément à l'article R.631-3 du code de la consommation,
                        le Client pourra saisir, soit l'une des juridictions territorialement compétentes
                        en vertu du code de procédure civile, soit la juridiction du lieu où il
                        demeurait au moment de la validation de la Commande ou de la survenance du
                        fait dommageable.
                    </div>
                    <h2 id="h030">30 - Loi applicable</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Les présentes CGV sont soumises au droit français.
                    </div>
                </div>
                <footer class="the-footer flex-wrap justify-between">
                    <p>
                        <span>COPYRIGHT &copy;</span>
                        <span>{{ new Date().getFullYear() }} </span>
                        <a href="https://www.happytal.com" target="_blank" rel="nofollow">happytal</a>
                        <span class="hidden sm:tw-inline-block">, Tous droits réservés</span>
                    </p>
                </footer>
            </div>
        </div>
    </div>
</template>
<script>
    import TheNavbarVerticalPublic from '@/components/navbar/TheNavbarVerticalPublic.vue'
    import VNavMenuCgv from '@/components/vertical-nav-menu/VerticalNavMenuCgv.vue'
    import $ from 'jquery'

    export default {
        components: {
            TheNavbarVerticalPublic,
            VNavMenuCgv,
        },
        data() {
            return {
                title: 'Conditions générales de vente Happydom'
            }
        },
        computed: {},
        methods: {},
        mounted() {
            $(document).ready(function () {
                $('.menu a').bind('click', function (e) {
                    e.preventDefault()
                    var target = $(this).attr("href")
                    $('html, body').stop().animate({
                        scrollTop: $(target).offset().top - 80
                    }, 600, function () {
                    })
                    return false
                })
            })
            $(window).scroll(function () {
                var scrollDistance = $(window).scrollTop()
                $('.divscroll h2').each(function (i) {
                    if ($(this).position().top - 80 <= scrollDistance) {
                        $('.menu a.selected').removeClass('selected')
                        $('.menu a').eq(i).addClass('selected')
                    }
                })
            }).scroll()
        }
    }
</script>

<style lang="scss">
    #cgv {
        .vs-sidebar .vs-sidebar--items {
            overflow: hidden;
            overflow: scroll !important;
            height: 100%;
        }

        #content-area {
            margin-top: 100px;
            padding-bottom: 80px;

            .vs-sidebar .vs-sidebar--items {
                overflow: hidden;
                height: 100%;
                position: fixed;
            }

            h2 {
                margin: 40px 0 20px 0;
            }

            .right {
                background: #fff;
                border-radius: .5rem;
                box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
                -webkit-transition: all .3s ease-in-out;
                padding: 30px;
            }

            footer {
                margin-top: 40px;

                p {
                    margin: 0 auto;
                    display: block;
                    text-align: center;
                }

            }
        }
    }
</style>
